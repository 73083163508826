<template>
  <ul 
    :class="['context_menu-list scroll-bar', {'macOSMenuStyler': isMacOS()}]">
    <li
      v-for="context in menuFiltered"
      :key="context.url"
      class="context_menu-item _firstLevel highlighted"
      @mouseleave="handleMouseLeave(context.name)"
      @mouseenter="handleMouseEnter">
      <ContextMenuHeader
        :is-active="forceShowMenu && context.name === activeName"
        :context="context"
        @context-click="showMenuOnMobile" />
      <MenuDropList
        v-if="isClientMounted && isDesktopDevice && !isTabletDevice"
        :class="{ 'keep-menu-open': shouldKeepMenuOpen(context.name) }"
        :context="context" />
      <transition
        v-else
        name="fade">
        <MenuDropListMobile
          v-if="forceShowMenu && context.name === activeName"
          :context="context"
          @close="closeDrop" />
      </transition>
    </li>
  </ul>
</template>

<script>
  import { mapGetter } from 'CommonUtils/store/state.js';
  import ContextMenuHeader from './ContextMenuHeader.vue';
  import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
  import { removeByCDPSegments } from 'CommonUtils/siteManager/segments.js';

  export default {
    name: 'ContextMenuList',
    components: {
      ContextMenuHeader,
      MenuDropList: defineAsyncComponent(() => import('./MenuDrop/MenuDropList.vue')),
      MenuDropListMobile: defineAsyncComponent(() => import('./MenuDrop/MenuDropListMobile.vue')),
    },
    setup() {
      const { isClientMounted, isDesktopDevice, isTabletDevice, isMacOS } = useNavigatorDetect();

      const forceShowMenu = ref(false);
      const activeName = ref('');
      const openedMenu = ref('');
      const timer = ref(null);
      const menuFiltered = ref([]);

      const contextMenu = mapGetter('page/getContextMenu');

      const showMenuOnMobile = (name) => {
        if (activeName.value !== name) {
          activeName.value = name;
          return (forceShowMenu.value = true);
        }
        forceShowMenu.value = !forceShowMenu.value;
        activeName.value = forceShowMenu.value ? name : '';
      };
      const closeDrop = () => {
        activeName.value = '';
        forceShowMenu.value = false;
      };

      /*  To don't close the opened drop list menu when mouse hovering the scrollbar
          between context menu item and drop list menu (small desktop screens) */

      const handleMouseLeave = (contextName) => {
        if (!isDesktopDevice.value) return;

        openedMenu.value = contextName;
        clearTimeout(timer);
        timer.value = setTimeout(() => {
          openedMenu.value = '';
        }, 300);
      };

      const handleMouseEnter = () => {
        if (!isDesktopDevice.value) return;
        clearTimeout(timer);
        openedMenu.value = '';
      };

      const shouldKeepMenuOpen = (contextName) => {
        return contextName === openedMenu.value;
      };

      const initMenu = () => {        
        menuFiltered.value = removeByCDPSegments(contextMenu);
      };
      
      watch(forceShowMenu, () => {
        if (document.getElementsByClassName('context_menu').length === 0) return;
        const el = document.getElementsByClassName('context_menu')[0];
        if (!el) return;

        if (forceShowMenu.value) {
          el.style.overflow = 'hidden';
        } else {
          el.style.overflowX = 'auto';
        }
      });

      onMounted(() => {
        initMenu();        
      });


      return {
        activeName,
        closeDrop,
        contextMenu,
        menuFiltered,
        forceShowMenu,
        handleMouseEnter,
        handleMouseLeave,
        isDesktopDevice,
        isMacOS,
        isClientMounted,
        isTabletDevice,
        shouldKeepMenuOpen,
        showMenuOnMobile,
      };
    },
  };
</script>

<style lang="less">
  .context_menu {
    &-list {
      cursor: inherit;
    }

    &-item {
      &._firstLevel {
        &:hover {
          > div:first-child {
            width: 100%;

            &:after {
              height: 3px;
              width: 100%;
              left: 0;
            }
          }
        }
      }
    }
  }
</style>
