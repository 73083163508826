<template>
  <div class="context-menu-container">
    <div :class="`context_menu ${placeClass}`">
      <ContextMenuList />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ContextMenuList from './ContextMenuList.vue';

export default {
  name: 'ContextMenu',
  components: {
    ContextMenuList,
  },
  computed: {
    ...mapGetters('page', {
      placeType: 'getPlaceType',
    }),
    placeClass() {
      return `place-${this.placeType}`;
    },
  },
};
</script>

<style lang="less">
.context_menu {
  cursor: inherit;
  &.place {
    &-products_list {
      cursor: inherit;
      .context_menu-list {
        cursor: inherit;
      }
      .context_menu-item {
        &:hover {
          border: none;
        }
      }
    }
  }
  &-list {
    cursor: inherit;
  }
}
</style>
